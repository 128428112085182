export const root = '/';
export const intro = '/intro';
export const contractorCabin = '/contractor-cabin';
export const equipmentSet = '/equipment-set';
export const mining = '/mining';
export const city = '/city';
export const mineManagement = '/mine-management';
export const pageNotFound = 'page-not-found';
export const wipPage = '/work-in-progress';
export const contractorStatsAndInfo = '/contractor/stats-and-info';
export const mineOwnerStatsAndInfo = '/mine-owner/stats-and-info';
export const mineOwnerMineCrew = '/mine-owner/mine-crew';
export const mineOwner = '/mine-owner/cabin';
export const landLord = '/landLord';
export const areaManagement = '/landLord/area-management';
export const landLordStats = '/landlord/stats-and-info';
export const hive = '/hive';
export const user = '/user/:accountName';
export const warehouse = '/warehouse';
export const serviceMarket = '/service-market';
export const createOrder = '/service-market/create-order';
export const createRentOrder = '/rental-hub/create-order';
export const rentalHub = '/rental-hub';
export const rentalHubOrder = '/rental-hub/contract/:contractId';

export const serviceMarketOrder = '/service-market/contract/:contractId';
export const inventoryItemPreview = '/inventory/:assetId';
export const faq = '/faq';
export const wasteland = '/wasteland';
export const engineer = '/engineer';
export const engineerEquipmentHall = '/engineer/equipment-hall';
export const unidentifiedActivity = '/unidentified-activity';
export const engineerTraining = '/engineer/training';
export const engineerStatAndInfo = '/engineer/stat-and-info';
