import cn from 'classnames';
import { FC } from 'react';
import styles from './styles.module.scss';

export const EliteDangerousLoader: FC<{ size?: 'small' | 'big' }> = ({
    size = 'small',
}) => {
    const arrowClass = size === 'small' ? styles.arrow : styles.arrowBig;
    return (
        <div className={styles.dankAssLoader}>
            <div className={styles.row}>
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.outer,
                        styles.outer18
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.outer,
                        styles.outer17
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.outer,
                        styles.outer16
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.outer,
                        styles.outer15
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.outer,
                        styles.outer14
                    )}
                />
            </div>
            <div className={styles.row}>
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.outer,
                        styles.outer1
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.outer,
                        styles.outer2
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.inner,
                        styles.inner6
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.inner,
                        styles.inner5
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.inner,
                        styles.inner4
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.outer,
                        styles.outer13
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.outer,
                        styles.outer12
                    )}
                />
            </div>
            <div className={styles.row}>
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.outer,
                        styles.outer3
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.outer,
                        styles.outer4
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.inner,
                        styles.inner1
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.inner,
                        styles.inner2
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.inner,
                        styles.inner3
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.outer,
                        styles.outer11
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.outer,
                        styles.outer10
                    )}
                />
            </div>
            <div className={styles.row}>
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.outer,
                        styles.outer5
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.outer,
                        styles.outer6
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.outer,
                        styles.outer7
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.up,
                        styles.outer,
                        styles.outer8
                    )}
                />
                <div
                    className={cn(
                        arrowClass,
                        styles.down,
                        styles.outer,
                        styles.outer9
                    )}
                />
            </div>
        </div>
    );
};
