import { isMainNet } from 'app/constants';
import { MergedInventoryWithAtomicAssets } from 'entities/atomicassets';

export const ENGINEER_CERTIFICATE_ID = isMainNet ? 619715 : 528605;

const TEST_NET_IN_GAME_IDS = [
    177577,
    177576,
    177575,
    177574,
    177573,
    177453,
    177452,
    177451,
    177450,
    177449,
    177448,
    177447,
    177446,
    177445,
    177444,
    176896,
    176895,
    176894,
    176891,
    176885,
    176879,
    176874,
    176873,
    176872,
    176871,
    176450,
    176449,
    176448,
    176447,
    176446,
    176451,
    505658,
    520417,
    522371,
    522372,
    522373,
    522374,
    524205,
    524206,
    524210,
    524211,
    524212,
    524214,
    524215,
    524216,
    524217,
    524218,
    524219,
    524220,
    524221,
    524222,
    524223,
    524224,
    524225,
    524284,
    524285,
    524286,
    524287,
    524288,
    524289,
    524290,
    524291,
    524292,
    524293,
    524294,
    524295,
    524296,
    524297,
    524298,
    524299,
    524300,
    524301,
    524302,
    524303,
    524304,
    524305,
    524306,
    524307,
    524134,
    524198,
    505659,
    176866,
    522137,
    522376,
    524199,
    524200,
    524201,
    524202,
    524203,
    524204,
    522377,
    423071,
    423072,
    423073,
    423074,
    423075,
    423037,
    423036,
    423016,
    423015,
    422959,
    423863,
    423864,
    423866,
    423867,
    424148,
    423858,
    423859,
    423860,
    423861,
    423862,
    423082,
    423845,
    423847,
    423850,
    423851,
    527838,
    527839,
    527840,
    527841,
    527842,
    527843,
    527844,
    527845,
    527846,
    527848,
    527849,
    527850,
    527851,
    527852,
    527853,
    527854,
    527855,
    527857,
    527966,
    527967,
    527968,
    527969,
    527970,
    527971,
    527972,
    527973,
    527974,
    527975,
    527976,
    527977,
    527978,
    527979,
    527980,
    527981,
    527982,
    527983,
    527984,
    527985,
    527986,
    527987,
    527988,
    527989,
    527990,
    527991,
    527992,
    527993,
    527994,
    527995,
    527996,
    527997,
    527998,
    527999,
    528000,
    528001,
    528008,
    528009,
    528012,
    528013,
    528014,
    528015,
    528016,
    528018,
    528017,
    528025,
    528026,
    528027,
    528028,
    528029,
    528030,
    528031,
    528032,
    528033,
    528042,
    528043,
    528044,
    528045,
    528046,
    528047,
    528048,
    528049,
    528050,
    528057,
    528058,
    528059,
    528060,
    528061,
    528062,
    528063,
    528064,
    528065,
    528074,
    528075,
    528076,
    528077,
    528078,
    528079,
    528080,
    528081,
    528082,
    528301,
    528302,
    528303,
    528304,
    528305,
    528306,
    528307,
    528308,
    528309,
    528083,
    528084,
    528085,
    528086,
    528087,
    528088,
    528089,
    528090,
    528091,
    528092,
    528093,
    528094,
    528095,
    528096,
    528097,
    528098,
    528099,
    528100,
    528101,
    528102,
    528103,
    528104,
    528105,
    528106,
    528107,
    528108,
    528109,
    528110,
    528111,
    528112,
    528113,
    528114,
    528115,
    528116,
    528117,
    528118,
    528119,
    528120,
    528121,
    528122,
    528123,
    528124,
    528125,
    528126,
    528127,
    528128,
    528129,
    528130,
    528131,
    528132,
    528133,
    528134,
    528135,
    528136,
    528137,
    528138,
    528139,
    528140,
    528141,
    528142,
    528143,
    528144,
    528145,
    528146,
    528147,
    528148,
    528149,
    528150,
    528151,
    528152,
    528153,
    528154,
    ENGINEER_CERTIFICATE_ID,
    528605,
    // Mines
    541314,
    541315,
    541316,
    541317,
    541318,
    541319,
    541320,
    541321,
    541322,
    541687,
    541688,
    541689,
    541690,
    541691,
    541692,
    541693,
    541694,
    541695,
    541696,
    541697,
    541698,
    541699,
    541700,
    541701,
    541702,
    541703,
    541704,
    541705,
    541706,
    541707,
    541708,
    541709,
    541710,
    541711,
    541712,
    541713,
    541714,
    541715,
    541716,
    541717,
    541718,
    541719,
    541720,
    541721,
    541722,
    541723,
    541724,
    541725,
    541726,
    541727,
    541728,
    541729,
    541730,
    541731,
];

const MAIN_NET_IN_GAME_IDS = [
    // Areas
    314749,
    314748,
    314747,
    314744,
    314743,
    // Equipment
    314738,
    314737,
    314735,
    314734,
    314733,
    314721,
    314719,
    314716,
    314715,
    314714,
    314712,
    314711,
    314710,
    314707,
    314706,
    314134,
    314130,
    314124,
    314117,
    314112,
    314105,
    314096,
    314090,
    314085,
    314084,
    617964,
    617965,
    617966,
    617967,
    617968,
    617969,
    617970,
    617971,
    617972,
    617973,
    617974,
    617975,
    617976,
    617977,
    617978,
    617979,
    617980,
    617981,
    617982,
    617983,
    617984,
    617985,
    617986,
    617987,
    617988,
    617989,
    617990,
    617995,
    617996,
    617997,
    617998,
    617999,
    618000,
    618001,
    618002,
    618003,
    618157,
    618158,
    618159,
    618160,
    618161,
    618162,
    618163,
    618164,
    618165,
    618166,
    618167,
    618168,
    618169,
    618170,
    618171,
    618172,
    618173,
    618174,
    618175,
    618176,
    618177,
    618178,
    618179,
    618180,
    618181,
    618182,
    618183,
    618184,
    618185,
    618186,
    618187,
    618188,
    618189,
    618190,
    618191,
    618192,
    619178,
    619179,
    619180,
    619181,
    619182,
    619183,
    619184,
    619185,
    619186,
    619190,
    619191,
    619192,
    619193,
    619195,
    619196,
    619197,
    619198,
    619199,
    619201,
    619202,
    619203,
    619204,
    619205,
    619206,
    619207,
    619208,
    619209,
    619211,
    619212,
    619213,
    619214,
    619215,
    619217,
    619218,
    619219,
    619220,
    619221,
    619222,
    619223,
    619224,
    619225,
    619226,
    619227,
    619228,
    619229,
    619231,
    619232,
    619233,
    619234,
    619235,
    619236,
    619237,
    619238,
    619244,
    619247,
    619249,
    619250,
    619251,
    619252,
    619253,
    619254,
    619255,
    619256,
    619257,
    619258,
    619259,
    619260,
    619261,
    619262,
    619263,
    619264,
    619265,
    619266,
    619267,
    619268,
    619269,
    619270,
    619271,
    619272,
    619273,
    619274,
    619276,
    619277,
    619278,
    619279,
    619280,
    619282,
    619283,
    619284,
    619289,
    619293,
    619294,
    619295,
    619296,
    619297,
    619298,
    619299,
    619301,
    619302,
    619306,
    619307,
    619308,
    619309,
    619311,
    619312,
    619313,
    619314,
    619317,
    // Structures
    314739,
    621854,
    621855,
    621856,
    621857,
    621858,
    621859,
    621860,
    621861,
    621862,
    621863,
    621864,
    621865,
    621866,
    621867,
    621868,
    621869,
    621870,
    621871,
    621873,
    621874,
    621875,
    621876,
    621877,
    621878,
    621879,
    621880,
    621881,
    621882,
    621883,
    621884,
    621885,
    621886,
    621887,
    621888,
    621889,
    621890,
    // Badges
    ENGINEER_CERTIFICATE_ID,
    // Schemas
    619366,
    619367,
    619368,
    619369,
    619370,
    619371,
    619372,
    619373,
    619374,
    619375,
    619376,
    619377,
    619378,
    619379,
    619380,
    619381,
    619382,
    619383,
    619386,
    619387,
    619388,
    619389,
    619390,
    619391,
    619392,
    619393,
    619394,
    619395,
    619396,
    619397,
    619398,
    619399,
    619400,
    619401,
    619402,
    619403,
    619405,
    619406,
    619408,
    619409,
    619410,
    619411,
    619412,
    619413,
    619414,
    619418,
    619420,
    619421,
    619422,
    619424,
    619425,
    619426,
    619428,
    619429,
    // Modules
    514947,
    514948,
    514949,
    514950,
    514951,
    514942,
    514943,
    514944,
    514945,
    514946,
    514927,
    514929,
    514934,
    514939,
    514941,
    514920,
    514922,
    514923,
    514924,
    514925,
    514913,
    514915,
    514916,
    514917,
    514918,
    // Mines
    624915,
    624916,
    624917,
    624918,
    624919,
    624920,
    624922,
    624928,
    625019,
    // Legendary equipment
    626395,
    626396,
    626397,
    626398,
    626399,
    626400,
    626401,
    626402,
    626403,
    314738,
    626351,
    626353,
    626354,
    626355,
    626356,
    685682,
    626358,
    626359,
    626360,
    314721,
    626370,
    626371,
    626372,
    626373,
    626374,
    626375,
    626376,
    626377,
    626378,
    314105,
    626361,
    626362,
    626363,
    626364,
    626365,
    626366,
    626367,
    626368,
    626369,
    // Legendary cutter
    626379,
    626380,
    626381,
    626382,
    626383,
    626384,
    626385,
    626386,
    626387,
];

export const IN_GAME_NFT_IDS = isMainNet
    ? MAIN_NET_IN_GAME_IDS
    : TEST_NET_IN_GAME_IDS;

export const ID_TO_INVENTORY = isMainNet
    ? ({
          314738: 'DME Wire',
          314737: 'DME Wire',
          314735: 'DME Wire',
          314734: 'DME Wire',
          314733: 'DME Wire',
          619178: 'DME Wire',
          619179: 'DME Wire',
          619180: 'DME Wire',
          619181: 'DME Wire',
          619182: 'DME Wire',
          619183: 'DME Wire',
          619184: 'DME Wire',
          619185: 'DME Wire',
          619186: 'DME Wire',
          619190: 'DME Wire',
          619191: 'DME Wire',
          619192: 'DME Wire',
          619193: 'DME Wire',
          619195: 'DME Wire',
          619196: 'DME Wire',
          619197: 'DME Wire',
          619198: 'DME Wire',
          619199: 'DME Wire',
          619201: 'DME Wire',
          619202: 'DME Wire',
          619203: 'DME Wire',
          619204: 'DME Wire',
          619205: 'DME Wire',
          619206: 'DME Wire',
          619207: 'DME Wire',
          619208: 'DME Wire',
          619209: 'DME Wire',
          619211: 'DME Wire',
          619212: 'DME Wire',
          619213: 'DME Wire',
          619214: 'DME Wire',
          619215: 'DME Wire',
          619217: 'DME Wire',
          619218: 'DME Wire',
          619219: 'DME Wire',
          619220: 'DME Wire',
          626351: 'DME Wire',
          626353: 'DME Wire',
          626354: 'DME Wire',
          626355: 'DME Wire',
          626356: 'DME Wire',
          685682: 'DME Wire',
          626358: 'DME Wire',
          626359: 'DME Wire',
          626360: 'DME Wire',
          314721: 'Plunging Blocks',
          314719: 'Plunging Blocks',
          314716: 'Plunging Blocks',
          314715: 'Plunging Blocks',
          314714: 'Plunging Blocks',
          619221: 'Plunging Blocks',
          619222: 'Plunging Blocks',
          619223: 'Plunging Blocks',
          619224: 'Plunging Blocks',
          619225: 'Plunging Blocks',
          619226: 'Plunging Blocks',
          619227: 'Plunging Blocks',
          619228: 'Plunging Blocks',
          619229: 'Plunging Blocks',
          619231: 'Plunging Blocks',
          619232: 'Plunging Blocks',
          619233: 'Plunging Blocks',
          619234: 'Plunging Blocks',
          619235: 'Plunging Blocks',
          619236: 'Plunging Blocks',
          619237: 'Plunging Blocks',
          619238: 'Plunging Blocks',
          619244: 'Plunging Blocks',
          619247: 'Plunging Blocks',
          619249: 'Plunging Blocks',
          619250: 'Plunging Blocks',
          619251: 'Plunging Blocks',
          619252: 'Plunging Blocks',
          619253: 'Plunging Blocks',
          619254: 'Plunging Blocks',
          619255: 'Plunging Blocks',
          619256: 'Plunging Blocks',
          619257: 'Plunging Blocks',
          619258: 'Plunging Blocks',
          619259: 'Plunging Blocks',
          619260: 'Plunging Blocks',
          619261: 'Plunging Blocks',
          619262: 'Plunging Blocks',
          619263: 'Plunging Blocks',
          619264: 'Plunging Blocks',
          619265: 'Plunging Blocks',
          626370: 'Plunging Blocks',
          626371: 'Plunging Blocks',
          626372: 'Plunging Blocks',
          626373: 'Plunging Blocks',
          626374: 'Plunging Blocks',
          626375: 'Plunging Blocks',
          626376: 'Plunging Blocks',
          626377: 'Plunging Blocks',
          626378: 'Plunging Blocks',

          314712: 'Сutter',
          314711: 'Сutter',
          314710: 'Сutter',
          314707: 'Сutter',
          314706: 'Сutter',

          617964: 'Сutter',
          617965: 'Сutter',
          617966: 'Сutter',
          617967: 'Сutter',
          617968: 'Сutter',
          617969: 'Сutter',
          617970: 'Сutter',
          617971: 'Сutter',
          617972: 'Сutter',
          617973: 'Сutter',
          617974: 'Сutter',
          617975: 'Сutter',
          617976: 'Сutter',
          617977: 'Сutter',
          617978: 'Сutter',
          617979: 'Сutter',
          617980: 'Сutter',
          617981: 'Сutter',
          617982: 'Сutter',
          617983: 'Сutter',
          617984: 'Сutter',
          617985: 'Сutter',
          617986: 'Сutter',
          617987: 'Сutter',
          617988: 'Сutter',
          617989: 'Сutter',
          617990: 'Сutter',
          617995: 'Сutter',
          617996: 'Сutter',
          617997: 'Сutter',
          617998: 'Сutter',
          617999: 'Сutter',
          618000: 'Сutter',
          618001: 'Сutter',
          618002: 'Сutter',
          618003: 'Сutter',
          626379: 'Сutter',
          626380: 'Сutter',
          626381: 'Сutter',
          626382: 'Сutter',
          626383: 'Сutter',
          626384: 'Сutter',
          626385: 'Сutter',
          626386: 'Сutter',
          626387: 'Сutter',

          314134: 'Delaminator',
          314130: 'Delaminator',
          314124: 'Delaminator',
          314117: 'Delaminator',
          314112: 'Delaminator',
          618157: 'Delaminator',
          618158: 'Delaminator',
          618159: 'Delaminator',
          618160: 'Delaminator',
          618161: 'Delaminator',
          618162: 'Delaminator',
          618163: 'Delaminator',
          618164: 'Delaminator',
          618165: 'Delaminator',
          618166: 'Delaminator',
          618167: 'Delaminator',
          618168: 'Delaminator',
          618169: 'Delaminator',
          618170: 'Delaminator',
          618171: 'Delaminator',
          618172: 'Delaminator',
          618173: 'Delaminator',
          618174: 'Delaminator',
          618175: 'Delaminator',
          618176: 'Delaminator',
          618177: 'Delaminator',
          618178: 'Delaminator',
          618179: 'Delaminator',
          618180: 'Delaminator',
          618181: 'Delaminator',
          618182: 'Delaminator',
          618183: 'Delaminator',
          618184: 'Delaminator',
          618185: 'Delaminator',
          618186: 'Delaminator',
          618187: 'Delaminator',
          618188: 'Delaminator',
          618189: 'Delaminator',
          618190: 'Delaminator',
          618191: 'Delaminator',
          618192: 'Delaminator',
          626395: 'Delaminator',
          626396: 'Delaminator',
          626397: 'Delaminator',
          626398: 'Delaminator',
          626399: 'Delaminator',
          626400: 'Delaminator',
          626401: 'Delaminator',
          626402: 'Delaminator',
          626403: 'Delaminator',
          314105: 'Wandering Reactor',
          314096: 'Wandering Reactor',
          314090: 'Wandering Reactor',
          314085: 'Wandering Reactor',
          314084: 'Wandering Reactor',
          619266: 'Wandering Reactor',
          619267: 'Wandering Reactor',
          619268: 'Wandering Reactor',
          619269: 'Wandering Reactor',
          619270: 'Wandering Reactor',
          619271: 'Wandering Reactor',
          619272: 'Wandering Reactor',
          619273: 'Wandering Reactor',
          619274: 'Wandering Reactor',
          619276: 'Wandering Reactor',
          619277: 'Wandering Reactor',
          619278: 'Wandering Reactor',
          619279: 'Wandering Reactor',
          619280: 'Wandering Reactor',
          619282: 'Wandering Reactor',
          619283: 'Wandering Reactor',
          619284: 'Wandering Reactor',
          619289: 'Wandering Reactor',
          619293: 'Wandering Reactor',
          619294: 'Wandering Reactor',
          619295: 'Wandering Reactor',
          619296: 'Wandering Reactor',
          619297: 'Wandering Reactor',
          619298: 'Wandering Reactor',
          619299: 'Wandering Reactor',
          619301: 'Wandering Reactor',
          619302: 'Wandering Reactor',
          619306: 'Wandering Reactor',
          619307: 'Wandering Reactor',
          619308: 'Wandering Reactor',
          619309: 'Wandering Reactor',
          619311: 'Wandering Reactor',
          619312: 'Wandering Reactor',
          619313: 'Wandering Reactor',
          619314: 'Wandering Reactor',
          619317: 'Wandering Reactor',
          626361: 'Wandering Reactor',
          626362: 'Wandering Reactor',
          626363: 'Wandering Reactor',
          626364: 'Wandering Reactor',
          626365: 'Wandering Reactor',
          626366: 'Wandering Reactor',
          626367: 'Wandering Reactor',
          626368: 'Wandering Reactor',
          626369: 'Wandering Reactor',

          314739: 'Mine',
          621854: 'Mine',
          621855: 'Mine',
          621856: 'Mine',
          621857: 'Mine',
          621858: 'Mine',
          621859: 'Mine',
          621860: 'Mine',
          621861: 'Mine',
          621862: 'Mine',
          621863: 'Mine',
          621864: 'Mine',
          621865: 'Mine',
          621866: 'Mine',
          621867: 'Mine',
          621868: 'Mine',
          621869: 'Mine',
          621870: 'Mine',
          621871: 'Mine',
          621873: 'Mine',
          621874: 'Mine',
          621875: 'Mine',
          621876: 'Mine',
          621877: 'Mine',
          621878: 'Mine',
          621879: 'Mine',
          621880: 'Mine',
          621881: 'Mine',
          621882: 'Mine',
          621883: 'Mine',
          621884: 'Mine',
          621885: 'Mine',
          621886: 'Mine',
          621887: 'Mine',
          621888: 'Mine',
          621889: 'Mine',
          621890: 'Mine',
          624915: 'Mine',
          624916: 'Mine',
          624917: 'Mine',
          624918: 'Mine',
          624919: 'Mine',
          624920: 'Mine',
          624922: 'Mine',
          624928: 'Mine',
          625019: 'Mine',
          [ENGINEER_CERTIFICATE_ID]: 'Engineer Certificate',
          314744: 'Space Debris',
          314749: 'DME Springs',
          314748: 'Lava',
          314747: 'Glaciers',
          314743: 'Rock Fields',
      } as const)
    : ({
          177577: 'DME Wire',
          177576: 'DME Wire',
          177575: 'DME Wire',
          177574: 'DME Wire',
          177573: 'DME Wire',
          528042: 'DME Wire',
          528043: 'DME Wire',
          528044: 'DME Wire',
          528045: 'DME Wire',
          528046: 'DME Wire',
          528047: 'DME Wire',
          528048: 'DME Wire',
          528049: 'DME Wire',
          528050: 'DME Wire',
          528057: 'DME Wire',
          528058: 'DME Wire',
          528059: 'DME Wire',
          528060: 'DME Wire',
          528061: 'DME Wire',
          528062: 'DME Wire',
          528063: 'DME Wire',
          528064: 'DME Wire',
          528065: 'DME Wire',
          528074: 'DME Wire',
          528075: 'DME Wire',
          528076: 'DME Wire',
          528077: 'DME Wire',
          528078: 'DME Wire',
          528079: 'DME Wire',
          528080: 'DME Wire',
          528081: 'DME Wire',
          528082: 'DME Wire',
          528301: 'DME Wire',
          528302: 'DME Wire',
          528303: 'DME Wire',
          528304: 'DME Wire',
          528305: 'DME Wire',
          528306: 'DME Wire',
          528307: 'DME Wire',
          528308: 'DME Wire',
          528309: 'DME Wire',
          541687: 'DME Wire',
          541688: 'DME Wire',
          541689: 'DME Wire',
          541690: 'DME Wire',
          541691: 'DME Wire',
          541692: 'DME Wire',
          541693: 'DME Wire',
          541694: 'DME Wire',
          541695: 'DME Wire',
          177453: 'Plunging Blocks',
          177452: 'Plunging Blocks',
          177451: 'Plunging Blocks',
          177450: 'Plunging Blocks',
          177449: 'Plunging Blocks',
          528083: 'Plunging Blocks',
          528084: 'Plunging Blocks',
          528085: 'Plunging Blocks',
          528086: 'Plunging Blocks',
          528087: 'Plunging Blocks',
          528088: 'Plunging Blocks',
          528089: 'Plunging Blocks',
          528090: 'Plunging Blocks',
          528091: 'Plunging Blocks',
          528092: 'Plunging Blocks',
          528093: 'Plunging Blocks',
          528094: 'Plunging Blocks',
          528095: 'Plunging Blocks',
          528096: 'Plunging Blocks',
          528097: 'Plunging Blocks',
          528098: 'Plunging Blocks',
          528099: 'Plunging Blocks',
          528100: 'Plunging Blocks',
          528101: 'Plunging Blocks',
          528102: 'Plunging Blocks',
          528103: 'Plunging Blocks',
          528104: 'Plunging Blocks',
          528105: 'Plunging Blocks',
          528106: 'Plunging Blocks',
          528107: 'Plunging Blocks',
          528108: 'Plunging Blocks',
          528109: 'Plunging Blocks',
          528110: 'Plunging Blocks',
          528111: 'Plunging Blocks',
          528112: 'Plunging Blocks',
          528113: 'Plunging Blocks',
          528114: 'Plunging Blocks',
          528115: 'Plunging Blocks',
          528116: 'Plunging Blocks',
          528117: 'Plunging Blocks',
          528118: 'Plunging Blocks',
          541705: 'Plunging Blocks',
          541706: 'Plunging Blocks',
          541707: 'Plunging Blocks',
          541708: 'Plunging Blocks',
          541709: 'Plunging Blocks',
          541710: 'Plunging Blocks',
          541711: 'Plunging Blocks',
          541712: 'Plunging Blocks',
          541713: 'Plunging Blocks',
          177448: 'Сutter',
          177447: 'Сutter',
          177446: 'Сutter',
          177445: 'Сutter',
          177444: 'Сutter',
          // Сutter common
          617964: 'Сutter',
          617965: 'Сutter',
          617966: 'Сutter',
          617967: 'Сutter',
          617968: 'Сutter',
          617969: 'Сutter',
          617970: 'Сutter',
          617971: 'Сutter',
          617972: 'Сutter',
          527838: 'Сutter',
          527839: 'Сutter',
          527840: 'Сutter',
          527841: 'Сutter',
          527842: 'Сutter',
          527843: 'Сutter',
          527844: 'Сutter',
          527845: 'Сutter',
          527857: 'Сutter',
          527848: 'Сutter',
          527849: 'Сutter',
          527850: 'Сutter',
          527851: 'Сutter',
          527852: 'Сutter',
          527853: 'Сutter',
          527854: 'Сutter',
          527855: 'Сutter',
          527966: 'Сutter',
          527967: 'Сutter',
          527968: 'Сutter',
          527969: 'Сutter',
          527970: 'Сutter',
          527971: 'Сutter',
          527972: 'Сutter',
          527973: 'Сutter',
          527974: 'Сutter',
          527975: 'Сutter',
          527976: 'Сutter',
          527977: 'Сutter',
          527978: 'Сutter',
          527979: 'Сutter',
          527980: 'Сutter',
          527981: 'Сutter',
          527982: 'Сutter',
          527983: 'Сutter',
          541696: 'Сutter',
          541697: 'Сutter',
          541698: 'Сutter',
          541699: 'Сutter',
          541700: 'Сutter',
          541701: 'Сutter',
          541702: 'Сutter',
          541703: 'Сutter',
          541704: 'Сutter',

          176896: 'Delaminator',
          176895: 'Delaminator',
          176894: 'Delaminator',
          176891: 'Delaminator',
          176885: 'Delaminator',
          527984: 'Delaminator',
          527985: 'Delaminator',
          527986: 'Delaminator',
          527987: 'Delaminator',
          527988: 'Delaminator',
          527989: 'Delaminator',
          527990: 'Delaminator',
          527991: 'Delaminator',
          527992: 'Delaminator',
          527993: 'Delaminator',
          527994: 'Delaminator',
          527995: 'Delaminator',
          527996: 'Delaminator',
          527997: 'Delaminator',
          527998: 'Delaminator',
          527999: 'Delaminator',
          528000: 'Delaminator',
          528001: 'Delaminator',
          528008: 'Delaminator',
          528009: 'Delaminator',
          528012: 'Delaminator',
          528013: 'Delaminator',
          528014: 'Delaminator',
          528015: 'Delaminator',
          528016: 'Delaminator',
          528018: 'Delaminator',
          528017: 'Delaminator',
          528025: 'Delaminator',
          528026: 'Delaminator',
          528027: 'Delaminator',
          528028: 'Delaminator',
          528029: 'Delaminator',
          528030: 'Delaminator',
          528031: 'Delaminator',
          528032: 'Delaminator',
          528033: 'Delaminator',
          541723: 'Delaminator',
          541724: 'Delaminator',
          541725: 'Delaminator',
          541726: 'Delaminator',
          541727: 'Delaminator',
          541728: 'Delaminator',
          541729: 'Delaminator',
          541730: 'Delaminator',
          541731: 'Delaminator',
          176879: 'Wandering Reactor',
          176874: 'Wandering Reactor',
          176873: 'Wandering Reactor',
          176872: 'Wandering Reactor',
          176871: 'Wandering Reactor',
          528119: 'Wandering Reactor',
          528120: 'Wandering Reactor',
          528121: 'Wandering Reactor',
          528122: 'Wandering Reactor',
          528123: 'Wandering Reactor',
          528124: 'Wandering Reactor',
          528125: 'Wandering Reactor',
          528126: 'Wandering Reactor',
          528127: 'Wandering Reactor',
          528128: 'Wandering Reactor',
          528129: 'Wandering Reactor',
          528130: 'Wandering Reactor',
          528131: 'Wandering Reactor',
          528132: 'Wandering Reactor',
          528133: 'Wandering Reactor',
          528134: 'Wandering Reactor',
          528135: 'Wandering Reactor',
          528136: 'Wandering Reactor',
          528137: 'Wandering Reactor',
          528138: 'Wandering Reactor',
          528139: 'Wandering Reactor',
          528140: 'Wandering Reactor',
          528141: 'Wandering Reactor',
          528142: 'Wandering Reactor',
          528143: 'Wandering Reactor',
          528144: 'Wandering Reactor',
          528145: 'Wandering Reactor',
          528146: 'Wandering Reactor',
          528147: 'Wandering Reactor',
          528148: 'Wandering Reactor',
          528149: 'Wandering Reactor',
          528150: 'Wandering Reactor',
          528151: 'Wandering Reactor',
          528152: 'Wandering Reactor',
          528153: 'Wandering Reactor',
          528154: 'Wandering Reactor',
          541714: 'Wandering Reactor',
          541715: 'Wandering Reactor',
          541716: 'Wandering Reactor',
          541717: 'Wandering Reactor',
          541718: 'Wandering Reactor',
          541719: 'Wandering Reactor',
          541720: 'Wandering Reactor',
          541721: 'Wandering Reactor',
          541722: 'Wandering Reactor',
          176451: 'Mine',
          541314: 'Mine',
          541315: 'Mine',
          541316: 'Mine',
          541317: 'Mine',
          541318: 'Mine',
          541319: 'Mine',
          541320: 'Mine',
          541321: 'Mine',
          541322: 'Mine',
          [ENGINEER_CERTIFICATE_ID]: 'Engineer Certificate',
          176447: 'Space Debris',
          176450: 'DME Springs',
          176448: 'Glaciers',
          176446: 'Rock Fields',
      } as const);

export const mineAssetTemplateId = isMainNet
    ? [
          314739, 624915, 624916, 624917, 624918, 624919, 624920, 624922,
          624928, 625019,
      ]
    : [
          176451, 541314, 541315, 541316, 541317, 541318, 541319, 541320,
          541321, 541322,
      ];
export const areasAssetTemplateId = isMainNet
    ? [314749, 314748, 314747, 314744, 314743]
    : [176450, 176449, 176448, 176447, 176446];

export type InventoryIdTypeWithName = keyof typeof ID_TO_INVENTORY;
export type InventoryIdType = InventoryIdTypeWithName;
export type InventoryNameType = typeof ID_TO_INVENTORY[InventoryIdTypeWithName];

export enum ACTION_STATE_TO_ID {
    undefined,
    active,
    interrupted,
    finished,
    claimed,
}

export enum INDEX_POSITION_INVENTORY {
    undefined,
    inventoryId,
    nickname,
}

export enum INDEX_POSITION_HISTORY {
    undefined,
    actionId,
    nickname,
    contractId,
}

export enum INDEX_POSITION_USER {
    undefined,
    nickname,
}

export const deepminegame = 'deepmineappg';
export const deepminesmrt = 'deepminesrmt';
export const deepmineming = 'deepmineming';
export const deepmineengr = 'deepmineengr';
export const deepminestat = 'deepminestat';

// there are fewer zeros than in the game table to count the percentages, see the getCost method
export const dmeToUpgrade = {
    Common: {
        0: 100000,
        1: 200000,
        2: 250000,
        3: 450000,
        4: 700000,
        5: 1150000,
        6: 1850000,
        7: 2900000,
        8: 4500000,
        9: 7100000,
    },
    Uncommon: {
        0: 280000,
        1: 700000,
        2: 1000000,
        3: 1250000,
        4: 2100000,
        5: 3450000,
        6: 5400000,
        7: 8450000,
        8: 13200000,
        9: 20750000,
    },
    Rare: {
        0: 500000,
        1: 1300000,
        2: 1850000,
        3: 3750000,
        4: 6250000,
        5: 10050000,
        6: 15900000,
        7: 24850000,
        8: 38750000,
        9: 60850000,
    },
    Epic: {
        0: 910000,
        1: 2350000,
        2: 3400000,
        3: 11000000,
        4: 18350000,
        5: 29600000,
        6: 46700000,
        7: 72950000,
        8: 113700000,
        9: 178400000,
    },
    Legendary: {
        0: 1680000,
        1: 4350000,
        2: 6350000,
        3: 32250000,
        4: 53950000,
        5: 86950000,
        6: 137250000,
        7: 214250000,
        8: 333650000,
        9: 522850000,
    },
};

export type NumericMalfunctionProbability = 0 | 0.0001 | 0.025 | 0.1;

export const getMalfunctionProbability = (
    equipment: MergedInventoryWithAtomicAssets[number]
) => {
    let table:
        | Record<string, Record<number, NumericMalfunctionProbability>>
        | undefined;

    if (
        equipment.data.name === 'Сutter' ||
        equipment.data.name === 'Wandering Reactor'
    ) {
        table = {
            Common: {
                19: 0,
                18: 0,
                17: 0,
                16: 0,
                15: 0,
                14: 0,
                13: 0.0001,
                12: 0.0001,
                11: 0.0001,
                10: 0.0001,
                9: 0.0001,
                8: 0.0001,
                7: 0.025,
                6: 0.025,
                5: 0.025,
                4: 0.025,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Uncommon: {
                19: 0,
                18: 0,
                17: 0,
                16: 0,
                15: 0,
                14: 0,
                13: 0,
                12: 0.0001,
                11: 0.0001,
                10: 0.0001,
                9: 0.0001,
                8: 0.0001,
                7: 0.0001,
                6: 0.025,
                5: 0.025,
                4: 0.025,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Rare: {
                19: 0,
                18: 0,
                17: 0,
                16: 0,
                15: 0,
                14: 0,
                13: 0,
                12: 0,
                11: 0,
                10: 0.0001,
                9: 0.0001,
                8: 0.0001,
                7: 0.0001,
                6: 0.025,
                5: 0.025,
                4: 0.025,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Epic: {
                19: 0,
                18: 0,
                17: 0,
                16: 0,
                15: 0,
                14: 0,
                13: 0,
                12: 0,
                11: 0,
                10: 0,
                9: 0,
                8: 0.0001,
                7: 0.0001,
                6: 0.0001,
                5: 0.025,
                4: 0.025,
                3: 0.025,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Legendary: {
                19: 0,
                18: 0,
                17: 0,
                16: 0,
                15: 0,
                14: 0,
                13: 0,
                12: 0,
                11: 0,
                10: 0,
                9: 0,
                8: 0,
                7: 0,
                6: 0.0001,
                5: 0.0001,
                4: 0.0001,
                3: 0.025,
                2: 0.025,
                1: 0.1,
                0: 0.1,
            },
        };
    }

    if (
        equipment.data.name === 'Delaminator' ||
        equipment.data.name === 'DME Wire'
    ) {
        table = {
            Common: {
                39: 0,
                38: 0,
                37: 0,
                36: 0,
                35: 0,
                34: 0,
                33: 0,
                32: 0,
                31: 0,
                30: 0,
                29: 0,
                28: 0,
                27: 0.0001,
                26: 0.0001,
                25: 0.0001,
                24: 0.0001,
                23: 0.0001,
                22: 0.0001,
                21: 0.0001,
                20: 0.0001,
                19: 0.0001,
                18: 0.0001,
                17: 0.0001,
                16: 0.0001,
                15: 0.025,
                14: 0.025,
                13: 0.025,
                12: 0.025,
                11: 0.025,
                10: 0.025,
                9: 0.025,
                8: 0.025,
                7: 0.1,
                6: 0.1,
                5: 0.1,
                4: 0.1,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Uncommon: {
                39: 0,
                38: 0,
                37: 0,
                36: 0,
                35: 0,
                34: 0,
                33: 0,
                32: 0,
                31: 0,
                30: 0,
                29: 0,
                28: 0,
                27: 0,
                26: 0,
                25: 0,
                24: 0.0001,
                23: 0.0001,
                22: 0.0001,
                21: 0.0001,
                20: 0.0001,
                19: 0.0001,
                18: 0.0001,
                17: 0.0001,
                16: 0.0001,
                15: 0.0001,
                14: 0.0001,
                13: 0.025,
                12: 0.025,
                11: 0.025,
                10: 0.025,
                9: 0.025,
                8: 0.025,
                7: 0.025,
                6: 0.1,
                5: 0.1,
                4: 0.1,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Rare: {
                39: 0,
                38: 0,
                37: 0,
                36: 0,
                35: 0,
                34: 0,
                33: 0,
                32: 0,
                31: 0,
                30: 0,
                29: 0,
                28: 0,
                27: 0,
                26: 0,
                25: 0,
                24: 0,
                23: 0,
                22: 0,
                21: 0.0001,
                20: 0.0001,
                19: 0.0001,
                18: 0.0001,
                17: 0.0001,
                16: 0.0001,
                15: 0.0001,
                14: 0.0001,
                13: 0.0001,
                12: 0.025,
                11: 0.025,
                10: 0.025,
                9: 0.025,
                8: 0.025,
                7: 0.025,
                6: 0.1,
                5: 0.1,
                4: 0.1,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Epic: {
                39: 0,
                38: 0,
                37: 0,
                36: 0,
                35: 0,
                34: 0,
                33: 0,
                32: 0,
                31: 0,
                30: 0,
                29: 0,
                28: 0,
                27: 0,
                26: 0,
                25: 0,
                24: 0,
                23: 0,
                22: 0,
                21: 0,
                20: 0,
                19: 0,
                18: 0,
                17: 0.0001,
                16: 0.0001,
                15: 0.0001,
                14: 0.0001,
                13: 0.0001,
                12: 0.0001,
                11: 0.0001,
                10: 0.025,
                9: 0.025,
                8: 0.025,
                7: 0.025,
                6: 0.025,
                5: 0.1,
                4: 0.1,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Legendary: {
                39: 0,
                38: 0,
                37: 0,
                36: 0,
                35: 0,
                34: 0,
                33: 0,
                32: 0,
                31: 0,
                30: 0,
                29: 0,
                28: 0,
                27: 0,
                26: 0,
                25: 0,
                24: 0,
                23: 0,
                22: 0,
                21: 0,
                20: 0,
                19: 0,
                18: 0,
                17: 0,
                16: 0,
                15: 0,
                14: 0,
                13: 0.0001,
                12: 0.0001,
                11: 0.0001,
                10: 0.0001,
                9: 0.0001,
                8: 0.0001,
                7: 0.025,
                6: 0.025,
                5: 0.025,
                4: 0.025,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
        };
    }

    if (equipment.data.name === 'Plunging Blocks') {
        table = {
            Common: {
                29: 0,
                28: 0,
                27: 0,
                26: 0,
                25: 0,
                24: 0,
                23: 0,
                22: 0,
                21: 0,
                20: 0.0001,
                19: 0.0001,
                18: 0.0001,
                17: 0.0001,
                16: 0.0001,
                15: 0.0001,
                14: 0.0001,
                13: 0.0001,
                12: 0.0001,
                11: 0.025,
                10: 0.025,
                9: 0.025,
                8: 0.025,
                7: 0.025,
                6: 0.025,
                5: 0.1,
                4: 0.1,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Uncommon: {
                29: 0,
                28: 0,
                27: 0,
                26: 0,
                25: 0,
                24: 0,
                23: 0,
                22: 0,
                21: 0,
                20: 0,
                19: 0,
                18: 0.0001,
                17: 0.0001,
                16: 0.0001,
                15: 0.0001,
                14: 0.0001,
                13: 0.0001,
                12: 0.0001,
                11: 0.0001,
                10: 0.025,
                9: 0.025,
                8: 0.025,
                7: 0.025,
                6: 0.025,
                5: 0.1,
                4: 0.1,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Rare: {
                29: 0,
                28: 0,
                27: 0,
                26: 0,
                25: 0,
                24: 0,
                23: 0,
                22: 0,
                21: 0,
                20: 0,
                19: 0,
                18: 0,
                17: 0,
                16: 0,
                15: 0.0001,
                14: 0.0001,
                13: 0.0001,
                12: 0.0001,
                11: 0.0001,
                10: 0.0001,
                9: 0.025,
                8: 0.025,
                7: 0.025,
                6: 0.025,
                5: 0.025,
                4: 0.1,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Epic: {
                29: 0,
                28: 0,
                27: 0,
                26: 0,
                25: 0,
                24: 0,
                23: 0,
                22: 0,
                21: 0,
                20: 0,
                19: 0,
                18: 0,
                17: 0,
                16: 0,
                15: 0,
                14: 0,
                13: 0.0001,
                12: 0.0001,
                11: 0.0001,
                10: 0.0001,
                9: 0.0001,
                8: 0.0001,
                7: 0.025,
                6: 0.025,
                5: 0.025,
                4: 0.025,
                3: 0.1,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
            Legendary: {
                29: 0,
                28: 0,
                27: 0,
                26: 0,
                25: 0,
                24: 0,
                23: 0,
                22: 0,
                21: 0,
                20: 0,
                19: 0,
                18: 0,
                17: 0,
                16: 0,
                15: 0,
                14: 0,
                13: 0,
                12: 0,
                11: 0,
                10: 0,
                9: 0.0001,
                8: 0.0001,
                7: 0.0001,
                6: 0.0001,
                5: 0.025,
                4: 0.025,
                3: 0.025,
                2: 0.1,
                1: 0.1,
                0: 0.1,
            },
        };
    }

    const stability =
        Number(equipment.data['current capacity']) -
        Number(equipment.data.depreciation);

    return table?.[equipment.data.rarity][stability];
};

export const getMalfunctionProbabilityTranslation = (
    malfunctionProbability: NumericMalfunctionProbability
) => {
    if (malfunctionProbability === 0)
        return 'pages.equipmentSet.cardModal.malfunctionProbability.low';
    if (malfunctionProbability === 0.0001)
        return 'pages.equipmentSet.cardModal.malfunctionProbability.average';
    if (malfunctionProbability === 0.025 || malfunctionProbability === 0.1)
        return 'pages.equipmentSet.cardModal.malfunctionProbability.high';

    return 'pages.equipmentSet.cardModal.malfunctionProbability.low';
};
