import { UpgradeKitType } from 'features/engineer';
import { InventoryIdType } from 'entities/smartcontract';

const GIF_IMAGES = [492749];

export const TEST_NET_ASSETS_ID_MAP = new Map(
    Object.entries({
        177577: 314738,
        177576: 314737,
        177575: 314735,
        177574: 314734,
        177573: 314733,
        177453: 314721,
        177452: 314719,
        177451: 314716,
        177450: 314715,
        177449: 314714,
        177448: 314712,
        177447: 314711,
        177446: 314710,
        177445: 314707,
        177444: 314706,
        176896: 314134,
        176895: 314130,
        176894: 314124,
        176891: 314117,
        176885: 314112,
        176879: 314105,
        176874: 314096,
        176873: 314090,
        176872: 314085,
        176871: 314084,
        176450: 314749,
        176449: 314748,
        176448: 314747,
        176447: 314744,
        176446: 314743,
        411113: 492749,
        411112: 492748,
        411111: 492746,
        411110: 492745,
        411109: 492803,
        522137: 619375,
        524205: 619376,
        524206: 619377,
        524210: 619378,
        524211: 619379,
        524212: 619380,
        524214: 619381,
        524215: 619382,
        524216: 619383,
        // Engineer mine schemas
        522376: 619366,
        524134: 619367,
        524198: 619368,
        524199: 619369,
        524200: 619370,
        524201: 619371,
        524202: 619372,
        524203: 619373,
        524204: 619374,
        // Engineer equipment upgrade scheme uncommon
        522371: 619386,
        524217: 619387,
        524218: 619388,
        524220: 619389,
        524221: 619390,
        524222: 619391,
        524223: 619392,
        524224: 619393,
        524225: 619394,
        // Engineer equipment upgrade scheme rare
        522372: 619395,
        524284: 619396,
        524285: 619397,
        524286: 619398,
        524287: 619399,
        524288: 619400,
        524289: 619401,
        524290: 619402,
        524291: 619403,
        // Engineer equipment upgrade scheme epic
        522373: 619405,
        524292: 619406,
        524293: 619408,
        524294: 619409,
        524295: 619410,
        524296: 619411,
        524297: 619412,
        524298: 619413,
        524299: 619414,
        // Engineer equipment upgrade scheme legendary
        522374: 619418,
        524300: 619420,
        524301: 619421,
        524302: 619422,
        524303: 619424,
        524304: 619425,
        524305: 619426,
        524306: 619428,
        524307: 619429,
        // Cutter common
        527838: 617964,
        527839: 617965,
        527840: 617966,
        617967: 527841,
        617968: 527842,
        617969: 527843,
        617970: 527844,
        617971: 527845,
        617972: 527846,
        // Cutter uncommon
        527857: 617973,
        527848: 617974,
        527849: 617975,
        527850: 617976,
        527851: 617977,
        527852: 617978,
        527853: 617979,
        527854: 617980,
        527855: 617981,
        // Cutter rare
        527966: 617982,
        527967: 617983,
        527968: 617984,
        527969: 617985,
        527970: 617986,
        527971: 617987,
        527972: 617988,
        527973: 617989,
        527974: 617990,
        // Cutter epic
        527975: 617995,
        527976: 617996,
        527977: 617997,
        527978: 617998,
        527979: 617999,
        527980: 618000,
        527981: 618001,
        527982: 618002,
        527983: 618003,
        // Plunging Blocks common
        528083: 619221,
        528084: 619222,
        528085: 619223,
        528086: 619224,
        528087: 619225,
        528088: 619226,
        528089: 619227,
        528090: 619228,
        528091: 619229,
        // Plunging Blocks uncommon
        528092: 619231,
        528093: 619232,
        528094: 619233,
        528095: 619234,
        528096: 619235,
        528097: 619236,
        528098: 619237,
        528099: 619238,
        528100: 619244,
        // Plunging Blocks rare
        619247: 528101,
        619249: 528102,
        619250: 528103,
        619251: 528104,
        619252: 528105,
        619253: 528106,
        619254: 528107,
        619255: 528108,
        619256: 528109,
        // Plunging Blocks epic
        528110: 619257,
        528111: 619258,
        528112: 619259,
        528113: 619260,
        528114: 619261,
        528115: 619262,
        528116: 619263,
        528117: 619264,
        528118: 619265,
        // DME Wire common
        528042: 619178,
        528043: 619179,
        528044: 619180,
        528045: 619181,
        528046: 619182,
        528047: 619183,
        528048: 619184,
        528049: 619185,
        528050: 619186,
        // DME Wire uncommon
        528057: 619190,
        528058: 619191,
        528059: 619192,
        528060: 619193,
        528061: 619195,
        528062: 619196,
        528063: 619197,
        528064: 619198,
        528065: 619199,
        // DME Wire rare
        528074: 619201,
        528075: 619202,
        528076: 619203,
        528077: 619204,
        528078: 619205,
        528079: 619206,
        528080: 619207,
        528081: 619208,
        528082: 619209,
        // DME Wire epic
        528301: 619211,
        528302: 619212,
        528303: 619213,
        528304: 619214,
        528305: 619215,
        528306: 619217,
        528307: 619218,
        528308: 619219,
        528309: 619220,
        // Delaminator
        527984: 618157,
        527985: 618158,
        527986: 618159,
        527987: 618160,
        527988: 618161,
        527989: 618162,
        527990: 618163,
        527991: 618164,
        527992: 618165,
        527993: 618166,
        527994: 618167,
        527995: 618168,
        527996: 618169,
        527997: 618170,
        527998: 618171,
        527999: 618172,
        528000: 618173,
        528001: 618174,
        528008: 618175,
        528009: 618176,
        528012: 618177,
        528013: 618178,
        528014: 618179,
        528015: 618180,
        528016: 618181,
        528018: 618182,
        528017: 618183,
        528025: 618184,
        528026: 618185,
        528027: 618186,
        528028: 618187,
        528029: 618188,
        528030: 618189,
        528031: 618190,
        528032: 618191,
        528033: 618192,
        // Wandering reactor
        528119: 619266,
        528120: 619267,
        528121: 619268,
        528122: 619269,
        528123: 619270,
        528124: 619271,
        528125: 619272,
        528126: 619273,
        528127: 619274,
        528128: 619276,
        528129: 619277,
        528130: 619278,
        528131: 619279,
        528132: 619280,
        528133: 619282,
        528134: 619283,
        528135: 619284,
        528136: 619289,
        528137: 619293,
        528138: 619294,
        528139: 619295,
        528140: 619296,
        528141: 619297,
        528142: 619298,
        528143: 619299,
        528144: 619301,
        528145: 619302,
        528146: 619306,
        528147: 619307,
        528148: 619308,
        528149: 619309,
        528150: 619311,
        528151: 619312,
        528152: 619313,
        528153: 619314,
        528154: 619317,
        // Engineer Certificate
        528605: 619715,
        // Mine
        621854: 530681,
        621855: 530682,
        621856: 530683,
        621857: 530684,
        621858: 530685,
        621859: 530686,
        621860: 530687,
        621861: 530688,
        621862: 530689,
        621863: 530743,
        621864: 530744,
        621865: 530745,
        621866: 530746,
        621867: 530747,
        621868: 530748,
        621869: 530749,
        621870: 530750,
        621871: 530751,
        621873: 530752,
        621874: 530753,
        621875: 530754,
        621876: 530755,
        621877: 530756,
        621878: 530757,
        621879: 530758,
        621880: 530759,
        621881: 530760,
        621882: 530761,
        621883: 530762,
        621884: 530763,
        621885: 530764,
        621886: 530765,
        621887: 530766,
        621888: 530767,
        621889: 530768,
        621890: 530769,
        // Mines
        176451: 314739,
        541314: 624915,
        541315: 624916,
        541316: 624917,
        541317: 624918,
        541318: 624919,
        541319: 624920,
        541320: 624922,
        541321: 624928,
        541322: 625019,
        // Legendary
        541687: 626351,
        541688: 626353,
        541689: 626354,
        541690: 626355,
        541691: 626356,
        541692: 685682,
        541693: 626358,
        541694: 626359,
        541695: 626360,
        541696: 626379,
        541697: 626380,
        541698: 626381,
        541699: 626382,
        541700: 626383,
        541701: 626384,
        541702: 626385,
        541703: 626386,
        541704: 626387,
        541705: 626370,
        541706: 626371,
        541707: 626372,
        541708: 626373,
        541709: 626374,
        541710: 626375,
        541711: 626376,
        541712: 626377,
        541713: 626378,
        541714: 626361,
        541715: 626362,
        541716: 626363,
        541717: 626364,
        541718: 626365,
        541719: 626366,
        541720: 626367,
        541721: 626368,
        541722: 626369,
        541723: 626395,
        541724: 626396,
        541725: 626397,
        541726: 626398,
        541727: 626399,
        541728: 626400,
        541729: 626401,
        541730: 626402,
        541731: 626403,
    })
);

export const getImagePath = (templateId: InventoryIdType) => {
    const ID = TEST_NET_ASSETS_ID_MAP.get(String(templateId)) || templateId;
    const isGif = GIF_IMAGES.some((gifId) => gifId === ID);
    const ext = isGif ? 'gif' : 'webp';
    return `/img/cards/${ID}.${ext}`;
};

export const getKitImage = (type: UpgradeKitType) =>
    `/img/upgrade-kit/mine/repair-${type}.webp`;
