export const neutral3Color = '#262626';
export const orange6 = '#D87A16';
export const neutral4 = '#303030';
export const neutral1 = '#141414';
export const neutral2 = '#1D1D1D';
export const netural6 = '#5A5A5A';
export const neutral8 = '#ACACAC';
export const neutral9 = '#DBDBDB';
export const gold6 = '#D89614';
export const green6 = '#47FF40';
export const green4 = '#09E001';
export const primary6 = '#F5C913';
export const primary5 = '#CCA500';
export const red6 = '#D32029';
export const geekblue5 = '#0089FF';
export const purple6 = '#CB2EFF';
export const yellow7 = '#E8D639';
